.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 50px !important;
}

.icon--prefix {
  color: #a5a6ad;
  font-size: 18px;
  margin-right: 10px;
}

.ant-form-item-label>label::after {
  content: '' !important;
}

.textarea {
  background: #f2f6f5 !important;
  border-radius: 4px !important;
  min-height: 40px !important;
  font-size: 16px !important;
  border: none !important;
}

.input--small-width {
  width: 164px !important;
}

.input--medium-width {
  width: 250px !important;
}

.input--large-width {
  width: 100% !important;
  margin-right: 0 !important;
}

.input--width-25 {
  width: 25% !important;
}

.input--width-50 {
  width: 50% !important;
}

.input--width-75 {
  width: 75% !important;
}

.input--width-100 {
  width: 100% !important;
}

.input--transparent,
.input--transparent input,
.input--transparent .ant-select-selection--single {
  border: 0.5px solid #7070707c !important;
  border-radius: 5px !important;
  background: none !important;
}