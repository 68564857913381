.btn__supervisors {
  min-height: 40px;
  min-width: 80px;

  &.btn--white,
  &.btn--white:hover,
  &.btn--white:active,
  &.btn--white:focus {
    color: #fff !important;
    background: #006435 !important;
    border: none;
  }

  &.btn--cancel,
  &.btn--cancel:hover,
  &.btn--cancel:active,
  &.btn--cancel:focus {
    color: #fff;
    background: #b5b5b5;
    border: none;
  }
}
