.contractors-locked {

  &__container {
    width: 100%;

    & tr>th {
      font-weight: 600;
      cursor: unset;
    }

    &__icon-container{
      width: 1rem;
    }

    & .professional__header-container {
      position: unset;
    }

    & h1 {
      margin-left: 0 !important;
    }
  }
}

.add-btn {
  background-color: #0b734f;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.add-contractor-btn {
  background-color: #0b734f;
  color: white;
  border: 2px solid #0b734f;
  border-radius: 4px;

  &:hover {
    background-color: #10583f;
    color: white;
  }
}

.icons-container {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
  margin-right: 2rem;
}

.fas {
  padding-bottom: 5px;
}