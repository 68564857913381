.team-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  padding: 10px 20px;
  width: 100%;
  margin: 7px;

  &__title {
    width: 100%;
  }

  &__info-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 5px;

    & .ant-switch-disabled {
      margin-left: 30px !important;
      margin-right: 10px !important;
    }

  }

  & .icon__remove {
    cursor: pointer;
    float: right;
  }

  & span {
    width: 100%;
  }
}

.accounts__select-jobTitle {
  display: flex;
  align-items: center;

  & select {
    margin-left: 20px;
    width: 100px;
  }

  & span {
    font-size: 16px;
    font-weight: 500;
  }
}

.empty-row {
  & h2 {
    width: 100%;
    text-align: center;
    padding: 100px 0px;
  }
}

.team-actions-dropdown-content {

  b {
    margin: 0px 10px;
  }

  i {
    transform: translateY(3px);
  }

  .anticon-delete {
    color: #ff4d4f;
  }

  .anticon-mail{
    color: #0b734f;
  }

  .anticon {
    font-size: 20px;
  }
}