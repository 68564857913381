.reports {
  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    overflow: auto;
    max-height: calc(100vh - 60px);
    position: relative;
    // background-color: #E5E5E5;

    & h1 {
      width: 90%;
      margin: auto;
      margin-top: 50px;
    }

    .ant-empty {
      .ant-empty-image {
        height: initial;
        margin-bottom: 20px;

        .anticon-info-circle {
          font-size: 40px;
        }
      }

      .ant-empty-description{
        font-size:18px;
        font-weight: 500;
      }
    }
  }

  &__choose-user {
    width: 100%;
    padding: 15px;
    padding-bottom: 0px;
    text-align: right;

    .ant-select {
      width: 250px;
    }
  }



  &__power-bi {
    width: 100%;
    height: calc(100vh - 60px);
    bottom: 50px;
    border: none;
  }
}