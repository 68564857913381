.floating-contact-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #0B734F;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.contact-icon {
  width: 30px;
  height: 30px;
}

.form_txt{
  font-size: 1.1rem;
}

/* Background overlay for modal */
.modal-overlayy {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Shadow background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures it appears above other elements */
}

.tab-container {
  display: flex;
  gap: 10px;
}

.tab-button {
  font-size: large;
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-button:hover {
  background-color: #e0e0e0;
}

.tab-button.active {
  background-color: #0B734F;
  color: #fff;
}

.footer-buttons{
  display: none;
}


/* Modal container */
.slide-up-container {
  height:max-content;

  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  animation: slideUp 0.3s ease-out;
  z-index: 1001; /* Ensures it is above the overlay */
}

.up-container{
  height:max-content;
  padding-top: 5px;
  background-color: white;
  border-radius: 5px;
 
 
}

hr {
  border: 0;
  border-top: 2px solid #e7e6e6;
  margin: 5px 0;
}

/* Slide up animation */
@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.message-box {
  max-height: 90vh;
  overflow-y: auto;
}

.title-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.back-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
}

.button-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.countHead{
  text-align: left;
    font-size: large;
    color: #211f1f;
    margin-top: 10px;
}

.counttxt{
  text-align: center;
    font-size: large;
    color: #127412;

}

.option-button {
  display: flex;
  background-color: #f0f0f0;
  border: 1px solid #5f7a71;
  padding: 10px;
  margin: 10px 0;
  font-size: 14px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  
  border-radius: 5px;
  color: #0B734F;
  min-width: 100%;
  
}

.option-button:hover {
  background-color: #e0e0e0;
}

.arrow {
  color: #0B734F;
  font-size: 18px;
}

.export-button {
  padding: 10px;
  border-radius: 5px;
  background-color: #818181;
  color: white;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.export-button:hover {
  background-color: #606060;
}

/* Radio button group styling */
.radio-group {
  margin: 15px 0;
  color: #333;
}

.radio-group label {
  margin-right: 10px;
  font-weight: 500;
}

.radio-group input[type="radio"] {
  margin-right: 5px;
}

input[type="checkbox"] {
  height: 22px;
  width: 22px;
  accent-color: #0B734F;
}

/* Frequency dropdown styling */
.frequency-select {
  margin-top: 10px;
}

.frequency-select label {
  font-weight: 500;
  color: #333;
}

.frequency-select select {
  margin-left: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  outline: none;
}

/* Submit button styling */
.submit-button {
  margin-top: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  width: 100%;
  margin-right: 5px;
  cursor: pointer;
  font-weight: bold;
 
}

.submit-button:hover {
  background-color: #0056b3;
}

.from-label{
  margin-bottom: -10px;
  color: #3f3e3e;
  font-weight: 500;
}

/* Close button styling */
.close-button {
  background-color: #0B734F;
  border: none;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  color:#fff;
}

.success{
  text-align: center;
  font-size: large;
  color: #127412;
}

.close-button:hover {
  background-color: #c82333;
}

.home-button {
  background-color: #0B734F;
  border: none;
  padding: 10px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  color:#fff;
}

.home-button:hover {
  background-color: #11a873;
}

.job-schedule-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.txt_comma{
  margin-top: -10px;
  color: #616161;
  font-weight: 500;
}

.job-schedule-form input,
.job-schedule-form select,
.job-schedule-form textarea {
  padding: 10px;
  font-size: 1.0rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.2s ease;
}

.job-schedule-form input:focus,
.job-schedule-form select:focus,
.job-schedule-form textarea:focus {
  border-color: #0B734F;
}

textarea {
  resize: vertical;
  min-height: 60px;
}



.submit-button:hover {
  background-color: #0B734F;
}
/* ContactUsButton.css */

.title-bar {
  position: relative; 
  display: flex;
  justify-content: space-between; 
  align-items: center;
  padding-top: 10px; 
  padding-right: 10px;
  
}

.cancel-button {
  position: absolute;
  top: 5px; 
  right: 10px;
  background: none; 
  border: none; 
  font-size: 18px; 
  cursor: pointer; 
}

.txt_initial {
  flex: 1;
  text-align: left;
  font-size: 1.2rem;
  color: #232424;
  margin: 0;
  margin-left: 35px;
  margin-right: 35px;
}

.initialDesc{

  flex: 1;
  text-align: left;
  color: #666666;
  margin: 0;
  font-size: 17px;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: 500;
}

.initialDescMain{
  flex: 1;
  text-align: left;
  color: #6a6d6d;
  margin: 0;
  margin-left: 5px;
  margin-right: 35px;
}

.error{
  color: #a42626;
  margin-top: -10px;
}

.initial{
  flex: 1;
  text-align: left;
  font-size: 1.3rem;
  color: #232424;
  margin: 0;
  margin-left: 5px;
  margin-right: 35px;
  margin-bottom: 5px;
}

.back-button {
  background: none;
  border: none;
  font-size: 0.9rem;
  cursor: pointer;
  color: #095c3f;
  position: absolute;
  left: 0;
}

.cancel-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #e50e0e;
  position: absolute;
  right: 0;
}

.button-next-group {
  display: flex;
 flex-direction: row;
  width: 100%;

}

.option-next-button {
  flex: 1; /* Make each button take up equal vertical space */
  padding: 10px;
    margin-right: 10px;
    font-size: 15px;
  color: #fff;
  border: none;
  cursor: pointer;
  width: 50%;
}

.yes-button {
  background-color: #095c3f; /* Green */
}

.no-button {
  background-color: #e50e0e; /* Red */
}

.cancel-frm-button,
.next-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;
}

.cancel-frm-button {
  background-color: red;
  color: white;
  margin-right: 5px;

}

.next-button {
  background-color: green;
  color: white;
}

.next-button:disabled,
.submit-button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.time-range-container {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.time-range-label {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 18px;
}

.dropdown-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

.custom-dropdown {
  width: 100%;
  padding: 10px 16px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none; /* Hides default dropdown arrow */
  background-color: #fff;
  cursor: pointer;
}

.dropdown-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 14px;
  color: #666;
}

/* Optional: Style dropdown options */
.custom-dropdown option {
  font-size: 16px;
  padding: 8px;
}

.modal-contentt {
  
  background-color: white;
  padding: 14px 19px;
  border-radius: 5px;
  width: 600px;
  max-width: 100%;
  overflow-y: auto;
  max-height: 700px;
}



.right-panel {
  display: flex;
  width: 100%;
  max-height: 350px;
  
}

.safetyPg-panel, .users-panel, .period-panel ,.project-panel{

  flex: 1; 
  padding: 0px 15px;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  width: 25%;
}

.period-panel {
  border-right: none; 
}



.left-part {
  flex: 1; /* Take available space */
}

.right-part {
  flex: 1; /* Take available space */
  display: flex;
  flex-direction: column;
}

.countHead h4, .radio-group, .frequency-select {
  margin-bottom: 10px; /* Spacing for readability */
}

.header-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds spacing between the button and the title */
  margin-bottom: 20px; /* Adjust as needed for spacing */
}

.back-button {
  background-color: transparent;
  border: none;
  color: #000; 
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.back-button:hover {
  color: #095c3f; /* Darker blue on hover */
}

.header-title {
  font-size: 24px;
  
  margin: 0;
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

/* Modal content box */
.modal-content {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  width: 600px;
  max-width: 100%;
  overflow-y: auto;
  max-height: 400px;
}

/* Button styles */
.submit-button,
.close-button {
  
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

.submit-button {
  background-color: #095c3f;
  color: white;
 
}

.select-email-button{
  background-color: #095c3f;
  color: white;
  border: 1px solid #fff;
  padding: 3px 7px;
  border-radius: 5px;
}

.submit-button:hover {
  background-color: #095c3f;
}

.close-button {
  background-color: #f44336;
  color: white;
}

.close-button:hover {
  background-color: #d32f2f;
}

.button-group-label {
  grid-column: span 2; /* Buttons span both columns */
  display: flex;
  justify-content: space-between;
}

.left-input{
  width: 100%;
  margin-right: 5px;
}

.right-input{
  width: 100%;
  margin-right: 5px;
}

.success-toast {
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 4px;
  font-size: 14px;
}

.alert-heading {
  display: flex;
  align-items: center;
    padding: 10px 20px;
    border-radius: 3px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #f4f6f4;
    border-bottom: 1px solid #e9e9e9;
}

.back-button {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 18px;

}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Shadow background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.alert-content button {
  margin: 5px;
}

.email-modal-overlay {
  display: flex;
  /* other styles */
}
.email-modal-overlay.hidden {
  display: none;
}