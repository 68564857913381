.info {
  &__heading {
    &-icon {
      margin: 3px 10px;
      font-size: 16px;
    }
  }

  &__content {
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: space-between;

    &-container {
      width: 100%;

      .sa-divider {
        margin: 12px 0 !important;
      }

      .sa-ant-collapse {
        background-color: transparent;

        .ant-collapse-item {
          border: none;

          .ant-collapse-header {
            padding: 7px 16px;
            padding-left: 40px;

            .anticon {
              font-size: 14px;
            }

            b {
              font-size: 16px;
              font-weight: 500;
            }

            span {
              margin-left: 0.5rem;
              font-size: 14px;
              font-weight: initial;
            }
          }

          .ant-collapse-content {
            border: none !important;
            margin-left: 40px;
            .ant-collapse-content-box {
              padding: 0;
              padding-left: 40px;
              color: black;

              p {
                b {
                  font-size: 16px;
                  font-weight: 500;
                }

                span {
                  margin-left: 0.5rem;
                  font-size: 14px;
                  font-weight: initial;
                }
              }
            }
          }
        }
      }
    }

    &-UserCompany {
      border-bottom: 1px solid #e9e9e9;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }

    &-heading {
      font-size: 18px;
      font-weight: 500;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
    }

    &-value {
      font-size: 14px;
      padding-left: 5px;
    }
  }
}

.cuttext {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 350px;
  display: block;
}

.heading {
  font-size: 18px;
  border-bottom: 1px solid #e9e9e9;
  display: inline-block;
}

.apikeycopy {
  margin-top: 1px !important;
  transform: translateY(5px);
  margin-left: 10px !important;
  font-size: medium;
}

.companykeymodal {
  @media (min-width: 320px) {
    .sa-table {
      &-textLeft {
        tbody {
          tr {
            td {
              font-size: 11px;
              line-height: 30px;
              font-weight: 500;

              span {
                margin-left: 5px;
                font-weight: 400;
                font-size: 10px;
              }

              .cuttext {
                width: 200px;
              }

              .fa-clipboard {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 375px) {
    .sa-table {
      &-textLeft {
        tbody {
          tr {
            td {
              font-size: 13px;

              span {
                font-size: 12px;
              }

              .cuttext {
                width: 240px;
              }

              .fa-clipboard {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 414px) {
    .sa-table {
      &-textLeft {
        tbody {
          tr {
            td {
              .cuttext {
                width: 280px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 768px) {
    .sa-table {
      &-textLeft {
        tbody {
          tr {
            td {
              font-size: 16px;

              span {
                font-size: 14px;
              }

              .cuttext {
                width: 400px;
              }

              .fa-clipboard {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.user-guide {
  color: #22c55e;
  font-weight: bold;
  transition: all 0.5s;

  &:hover {
    text-decoration: underline;
    color: #22c55e;
  }
}
