.text {
  &-center {
    text-align: center !important;
  }

  &-left {
    text-align: left !important;
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

.opacity {
  &-0 {
    opacity: 0 !important;
  }
}

.height {
  &-initial {
    height: initial !important;
  }
}

.grid {
  display: grid;
}

.flex {
  display: flex !important;
}

.grid-cols {
  &-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.col-span {
  &-auto {
    grid-column: auto;
  }

  &-1 {
    grid-column: span 1 / span 1 !important;
  }

  &-2 {
    grid-column: span 2 / span 2 !important;
  }
}
