.invite {
  &__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0px auto;

    &-contracters {
      .ant-select-selection__rendered {
        padding-top: 0px !important;
      }
    }

    & .ant-col.ant-form-item-label>label {
      font-size: 16px;
    }
  }

  &-selection {
    display: flex;
    padding: 0% 0%;
    margin-bottom: 20px;

    select {
      height: 27px;
      width: 220px;
      font-size: 18px;
    }

    span {
      font-weight: 800;
      display: block;
    }
  }

  &__cityImage {
    & img {
      display: none;
    }
  }

  &__form {
    &-title {
      width: 100%;
      margin: 0 !important;
    }

    &-message {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
    }

    &-layout {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 5px !important;
    }
    &-label {
      font-weight: 500;
      padding-right: 20px !important;
      margin-bottom: 8px !important;
    }
    &-submit-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 20px;
    }
  }
}

@media screen and (min-width: 300px) {
  .dashboard {
    &__container {
      justify-content: flex-end;

      & img {
        width: 150px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .invite {
    &__container {
      margin: 0px 0px 0px 0px;
      padding-left: 7%;

      & .ant-col.ant-form-item-label>label {
        font-size: 14px;
      }
    }
    &__cityImage {
      display: flex;
      justify-content: center;

      & img {
        display: block;
      }
    }
    &__form {
      &-title {
        font-size: 20px;
      }
      &-submit-container {
        padding-top: 24%;
        justify-content: center;
      }
    }
  }
  .input--medium-height {
    font-size: 14px !important;
  }
  .ant-select-selection__placeholder {
    font-size: 14px;
  }

  .dashboard {
    &__container {
      justify-content: flex-end;
      padding: 6%;

      & img {
        width: 150px;
      }
    }
  }
  div.navigation__menu__bar {
    padding-left: 0;
    display: flex;
    justify-content: center;
  }
  
}

.add-company-option{
  border-top: 1px solid #eee !important;
  border-bottom: 1px solid #eee !important;
  color: #0b734f !important;
  font-weight: bold !important;
}

.parent-company-option {
  border-top: 1px solid #eee !important;
  background: #eee;
  border-bottom: 1px solid #eee !important;
}

.icon-highlight{
  color: #ff9900;
  font-size: 12px;
  margin-left: 5px;
}
