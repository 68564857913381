.ant-switch {
  &-disabled,
  &-inner,
  &-disabled:after {
    cursor: pointer !important;
  }

  &-disabled {
    transform: translateX(-30%);
  }

  &.ant-switch-checked,
  &.ant-switch-checked.ant-switch-disabled {
    background-color: #009d73 !important;
  }

  &,
  &.ant-switch-disabled {
    background-color: #d5d5d5 !important;
  }

  &,
  &.ant-switch-disabled {
    background-color: #c52423 !important;
  }
}
