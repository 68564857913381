.set-password-modal {
    margin-left: 10px;
}

.password-configration-form {
    &__btnContainer {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 15px;
        }
    }

    .ant-form-explain{
        margin-top: 7px;
        max-width: 364px;
    }
}