.projects-locked {
  &__view-container {
    color: grey;
  }

  &__container {
    width: 100%;

    & tr > th {
      font-weight: 600;
      cursor: unset;
    }

    & tr > td {
      text-align: center;
      padding: 0px !important;

      i {
        font-size: 15px;
      }
      .active-icon {
        cursor: pointer;
      }
    }

    & .fa-save{
      margin-right: 0.5rem;
    }

    & .professional__header-container {
      position: unset;
    }
  }
}
.add-btn {
  background-color: #0b734f;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.add-project-btn {
  background-color: #0b734f;
  color: white;
  border: 2px solid #0b734f;
  border-radius: 4px;

  &:hover {
    background-color: #10583f;
    color: white;
  }
}


