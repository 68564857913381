@import './sa.variables.scss';

.ant-modal {
  display: flex !important;
  justify-content: center !important;
  width: initial !important;

  .ant-modal-content {
    width: 100%;

    .ant-modal-close {
      top: 10px;
      right: 15px;

      .ant-modal-close-x {
        line-height: 0px !important;
        width: auto;
        height: auto;
      }
    }

    .ant-modal-header {
      padding: 5px 10px;
      position: relative;
      background: #F4F6F4 !important;

      .ant-modal-title {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 600;
      }
    }

    .ant-modal-body {
      padding: 5px;

      .ant-modal-body-content {
        display: flex;
        flex-direction: column;

        span {
          font-size: 16px;
          margin-bottom: 5px;
        }
      }

      &__form-footer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
      }

      .has-error {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .ant-form-explain {
          margin-left: 5px;
        }
      }
    }

    .ant-modal-footer {
      padding: 5px 10px;

      .ant-btn {
        font-size: $fontXS;
        height: initial;
        padding: 5px 10px;
      }

      .ant-modal-btn-close,
      .ant-modal-btn-close:hover,
      .ant-modal-btn-close:active,
      .ant-modal-btn-close:focus {
        color: #fff;
        background-color: #ff4d4f !important;
        border-color: #ff4d4f !important;
        border: none;
      }
    }
  }
}

@media (min-width: 375px) {
  .ant-modal {

    .ant-modal-content {

      .ant-modal-close {
        top: 12px;
      }

      .ant-modal-header {
        padding: 8px 10px;

        .ant-modal-title {
          font-size: 14px;
        }
      }

      .ant-modal-body {
        padding: 10px;
      }

      .ant-modal-footer {
        padding: 8px 10px;

        .ant-btn {
          height: initial;
          padding: 5px 10px;
        }
      }
    }
  }
}

@media (min-width: 414px) {
  .ant-modal {

    .ant-modal-content {
      // min-width: 450px;


      .ant-modal-close {
        top: 14px;
      }

      .ant-modal-header {
        padding: 10px 10px;

        .ant-modal-title {
          font-size: 16px;
        }
      }

      .ant-modal-body {
        padding: 10px;
      }

      .ant-modal-footer {
        padding: 10px 10px;

        .ant-btn {
          font-size: $fontBase;
          height: initial;
          padding: 5px 10px;
        }
      }
    }
  }
}


@media (min-width: 768px) {
  .ant-modal {

    .ant-modal-content {
      width: initial;
      min-width: 500px;
      max-width: 700px;

      .ant-modal-close {
        top: 14px;
      }

      .ant-modal-header {
        padding: 10px 10px;

        .ant-modal-title {
          font-size: 18px;
        }
      }

      .ant-modal-body {
        padding: 10px;
      }

      .ant-modal-footer {
        padding: 10px 10px;

        .ant-btn {
          height: initial;
          padding: 5px 10px;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .ant-modal {
    .ant-modal-content {
      width: initial;
      min-width: 550px;
      max-width: 800px;
    }
  }
}