// For Bordered Tables

.sa-table {
  border-collapse: collapse !important;
  width: 100%;
  margin: 10px 0px;
  border: 1px solid #eee;
}

.sa-table th {
  background-color: #f9f9f9 !important;
  border: 1px solid #eee;
  font-weight: bold !important;
  padding: 10px !important;
  text-align: center;
}

.sa-table tbody td {
  border: 1px solid #eee;
  box-shadow: none !important;
  height: auto !important;
  padding: 15px 5px !important;
  text-align: center;
  cursor: default;
}

.sa-table tbody td .ant-select-selection {
  border: 1px solid #d9d9d9;
  transition: all 0.5s;
}

.sa-table tbody td .ant-select-selection:hover {
  border: 1px solid #a7a4a4;
}

.sa-table tbody td .ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(176, 175, 175, 0.2);
}

.sa-table tbody td input {
  border: none;
  outline: none;
  background: none;
  text-align: center;

  &:focus {
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 35px;
  }
}

.sa-table thead th .sorter {
  -webkit-transform: translateX(5px) !important;
  transform: translateX(5px) !important;
}

.sa-table-scroll {
  height: 519px;
  overflow: auto;
  border-top: 1px solid #eee !important;

  .sa-table {
    thead {
      tr {
        th {
          position: sticky;
          top: 0;
        }
      }
    }
  }
}

.sa-table {
  &-textLeft {
    thead {
      tr {
        th {
          text-align: left !important;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: left !important;
        }
      }
    }
  }
}
