.step {
  &__container {
    margin-top: 50px !important;
    padding-left: 8% !important;

    & .ant-steps-item-icon {
      width: 25px;
      height: 25px;
      line-height: 25;
      margin-right: 0px !important;
    }

    & .ant-steps-item .ant-steps-icon .ant-steps-icon-dot {
      top: 0;
      left: 0;
    }

    & .ant-steps-item-title {
      top: -5px;

      &:after {
        height: 5px;
        left: 0;
        transition: all 0.8s ease;
      }
    }
  }
  &__header {
    width: 90%;
    margin: 0px auto 0px 30px;

    @media (min-height: 858px) {
      margin: 0px auto 60px 30px;
    }
  }

  &__title {
    padding-top: 85px;
  }
}

.ant-steps-item-icon .ant-steps-icon {
  position: absolute !important;
  top: 57% !important;
  left: 13px;
  transform: translate(-51%, -60%);
  font-size: 13px;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0px !important;
}
