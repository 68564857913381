.logout {
  position: absolute;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
  justify-content: center;
  bottom: 80px;
  width: 100%;
}

.logout span {
  padding-right: 20px;
}

.logout img {
  width: 24px;
}

.need-help {
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  
  & i {
    padding-left: 5px;
  }
}

@media screen and (max-width: 768px) {
  .logout {
    display: flex;
  }
}

@media screen and (min-width: 216px) {
  .logout span {
    padding-right: 8px;
    font-size: 17px;
  }

  .logout i {
    font-size: 17px;
  }
}

@media screen and (min-width: 1024px) {
  .logout span {
    font-size: 18px;
  }

  .logout i {
    font-size: 18px;
  }
}

@media screen and (min-height: 800px) {
  .need-help {
    position: absolute;
    bottom: 200px;
  }
}

// @media screen and (max-width: 768px) {
//   .ant-menu {
//     display: none;
//   }
// }

@media screen and (min-width: 1368px) {
  .logout span {
    font-size: 20px;
  }

  .logout i {
    font-size: 20px;
  }
}

@media screen and (min-height: 1000px) {
  .need-help {
    position: absolute;
    bottom: 350px;
  }
}

.main {

  .ant-menu-inline-collapsed {
    .ant-menu-item {
      display: flex;
      justify-content: center;
    }

    .ant-menu-submenu-title {
      display: flex;
      justify-content: center;
    }

    .need-help {
      span {
        display: none;
      }

      .fa-question-circle {
        font-size: 18px;
      }
    }
  }

  .ant-menu {
    background-color: #0B734F;
    padding: 30px 0px 20px 0px;
    height: calc(100vh - 60px);
    min-width: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .anticon {
      font-size: 22px !important;
      display: inline-flex;
    }

    .ant-menu-item-group-list {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .ant-menu-item-selected {
        background-color: rgba($color: #000000, $alpha: 0.2) !important;
        border-right: 3px solid white;

        span {
          font-weight: 400 !important;
        }
      }

      .ant-menu-item {
        height: 50px;
        display: flex;
        align-items: center;
        margin: 0;

        a {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: white !important;

          span {
            font-weight: 300;
          }
        }
      }

      .ant-menu-item::after {
        border: none;
      }

      .ant-menu-item:hover {
        background-color: rgba($color: #000000, $alpha: 0.2);
        border-color: white;
      }
    }

    .ant-menu-submenu-title:active {
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-color: white;
    }

    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      height: 50px;
      margin: 0;

      span {
        font-size: 18px;
        display: flex;
        align-items: center;
        color: white !important;
        font-weight: 300;
      }
    }
  }

  .ant-menu::before,
  .ant-menu::after {
    content: none;
  }

  &__body {
    display: flex;
  }
}

.ant-menu-submenu>.ant-menu {
  background-color: #0B734F !important;
  border-radius: initial !important;

  .anticon {
    font-size: 22px !important;
    display: inline-flex;
  }

  .ant-menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    margin: 0;

    a {
      display: flex;
      align-items: center;
      font-size: 18px;
      color: white !important;

      span {
        font-weight: 300;
      }
    }
  }

  .ant-menu-item:hover {
    background-color: rgba($color: #000000, $alpha: 0.2);
  }

  .ant-menu-item-selected {
    background-color: rgba($color: #000000, $alpha: 0.2) !important;
    border-right: 3px solid white;

    span {
      font-weight: 400 !important;
    }
  }

}