.plans {
  &__container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    margin: 100px auto;
  }
}

.plans-locked {
  position: relative;
  margin-top: 70px;
  height: 100%;
}

.plans-locked__container {
  width: 100%;
  height: 75%;
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.plans-locked-elements {
  height: auto;
  align-items: flex-end;
}

.plans-locked__title {
  width: 90%;
}

.plans-locked__title h1 {
  font-weight: 500;
  padding-left: 5%;
  padding-bottom: 7%;
}
