.add-company-modal {
  .ant-modal-body {
    padding: 10px !important;

    .ant-form {
      display: grid;
      gap: 0.5rem;

      label {
        font-size: 14px;
        font-weight: 500;
      }

      .ant-form-item-control-wrapper {
        .ant-form-explain {
          margin: 5px;
          font-size: 12px;
        }
      }
    }

    .ant-form-item {
      margin: 0 !important;

      .ant-form-item-children {
        input {
          height: 40px !important;
        }
      }
    }
  }
}

@media (min-width: 375px) {
  .add-company-modal {
    .ant-modal-body {
      padding: 10px 15px !important;
    }
  }
}

@media (min-width: 768px) {
  .add-company-modal {
    .ant-form {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}