@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Roboto';
}

body {
  background-color: #f8f8f8;
  overflow: hidden;
}

/*For Select*/

.ant-select-dropdown {
  min-width: 200px;
}

/* Ant Designs Dropdown Settings */

.ant-dropdown-menu-item:hover {
  background: #0b734f31 !important;
}

.ant-btn-default {
  &:hover {
    color: #0b734f !important;
    border-color: #0b734f !important;
  }

  &:focus {
    color: #0b734f !important;
    border-color: #0b734f !important;
  }
}

.ant-btn {
  &:hover {
    border-color: #0b734f !important;
  }

  &:focus {
    border-color: #0b734f !important;
  }
}

/* More Icon CSS */

.anticon-more {
  color: black;
  display: block;
  font-size: 15px;
}

.anticon-save {
  color: rgba(0, 0, 0, 0.65);
  font-size: 15px;
}

/*  */

.ant-message-notice:first-child {
  margin-top: 0px !important;
}

.ant-message-notice-content {
  padding: 15px;
}

.ant-message .anticon {
  font-size: 18px !important;
}

.ant-message-custom-content {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.ant-message-custom-content span {
  font-size: 14px !important;
  font-weight: bold !important;
}

/*Ant Tabs Bar CSS */
.ant-tabs-bar {
  margin: 0 !important;
}

.ant-tabs-ink-bar {
  background-color: #0b734f !important;
}

.ant-tabs-tab-active {
  color: #0b734f !important;
}

.ant-tabs-tab:hover {
  color: #0b734f !important;
}

/* Ant Tree Hover */
.sa-selectable .ant-tree-node-content-wrapper:hover {
  background-color: #0b734f31 !important;
}

.sa-selectable .ant-tree-node-content-wrapper-normal:hover {
  background-color: #0b734f31 !important;
}

.sa-selectable .ant-tree-node-selected {
  background-color: #0b734f31 !important;
}

.ant-tree-node-content-wrapper:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.ant-tree-node-content-wrapper-normal:hover {
  background-color: transparent !important;
  cursor: default !important;
}

.sa-selectable .ant-tree-node-selected {
  background-color: transparent !important;
  cursor: default !important;
}

.ant-tooltip-inner {
  font-size: 12px;
  font-weight: 600;
  cursor: default;
}

.ant-select-selection {
  font-size: 12px;
  text-align: center;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #fafafa;
}

.sa-checkbox-right {
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
  }
}