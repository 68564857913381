.pb {
    &-0 {
        padding-bottom: 0 !important;
    }
  }

.pt{
    &-0{
        padding-top: 0 !important;
    }
}