.forms__checkgroup-container {
  max-height: 250;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin: auto !important;
  margin-top: 30px !important;
}

.forms-div {
  opacity: 1;
  letter-spacing: 0;
}

.form__item {
  font-weight: normal;
  margin-bottom: 30px;
}

.form__item {
  i {
    cursor: pointer;
  }

  &-title.ant-checkbox-wrapper,
  &-title {
    font-size: 13px;

    @media only screen and (min-width: 1366px) {
      font-size: 16px;
    }
  }
}

.modal__questions {
  width: 60% !important;
}

.modal__questions-container {
  display: flex;
  justify-content: space-between;
}

.modal__questions-container i {
  cursor: pointer;
}

.swal2-close {
  color: #000000;
}

.anticon.anticon-question-circle svg {
  color: #797979;
  opacity: 0.44;
}

.ant-modal-body .anticon.anticon-question-circle svg {
  color: blue;
  opacity: 0.44;
}

.form__spinner {
  position: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  z-index: 40;
  margin-top: 70px;
  width: 100%;
  height: 100%;
}

.modal--scrollable {
  max-height: 600px;
}

.forms__checkgroup-container .ant-row {
  display: flex;
  flex-wrap: wrap;
}
