.salesmodal {
  &-display {
    color: white;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
  }
  &__container {
    border-radius: 4px;
    border: 2px solid white;
    position: fixed;
    width: 30%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
  }
  &-body {
    background: white;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #808080;
    text-align: center;
  }
  &-footer {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: space-evenly; 
  }
}

.hide {
    display: none;
}
