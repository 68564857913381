.btn__container {
  height: 42px !important;
}

.btn__extra-small {
  padding-right: 23px;
  padding-left: 23px;
}

.btn__small {
  width: 129px;
  max-width: 129px;
}

.btn__medium {
  width: 250px;
  max-width: 250px;
}

.btn__large {
  width: 310px;
  max-width: 310px;
}

.btn__extra-large {
  width: 100%;
}

.btn--bold {
  font-weight: bold;
}
