.admin-guide-modal {
  .ant-modal-content {
    min-height: 80vh !important;
    min-width: 60vw !important;

    .ant-modal-close {
      padding: 5px;
      background-color: white;
      border-radius: 100%;
    }

    .ant-modal-body {
      padding: 0px !important;
      height: 100%;
    }
  }

  .admin-guide {
    height: 100%;
    width: 100%;
  }
}
