.account-disabled {
    &-display {
        color: white;
        position: fixed; /* Stay in place */
        z-index: 10000; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: white;
    }
    &__container {
        border-radius: 4px;
        border: 2px solid white;
        width: 80%;
        top: 50%;
        left: 50%;
        width: 100%;
    }
    &-body {
        background: white;
        height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #808080;
        text-align: center;
        padding: 20px;
        box-shadow: 5px 5px 20px #808080;
    }
    
}

.hide {
    display: none;
}