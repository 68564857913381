.ant-upload {
  &-drag-container {
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;
  }

  &.ant-upload-drag {
    height: 60px !important;
    width: 350px !important;
    background: none !important;

    & p.ant-upload-text {
      font-size: 13px !important;
      text-decoration: underline;
    }

    & .ant-upload {
      padding: 0 !important;
    }

    & .ant-upload-btn{
      height: initial !important;
    }

    & p.ant-upload-drag-icon {
      margin: 5px 10px !important;
    }
  }

  &-list.ant-upload-list-text {
    top: 5px;
    left: 5px;
  }

  &-list-item-info {
    padding-right: 22px !important;
    max-width: 100%;
    border-radius: 5px;
  }

  &-list-item-info:hover {
    background-color: rgba(11, 115, 79, .19) !important;
  }
}

.ant-upload.ant-upload-drag:hover {
  border-color: #26563e !important;
}