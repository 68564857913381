/* LoadingIndicator.css */
.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust height as needed */
  }
  
  .loading-gif {
    width: 50px; /* Adjust width as needed */
    height: 50px;
  }
  