.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my {
  &-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.mt {
  &-0 {
    margin-top: 0 !important;
  }

  &-10 {
    margin-top: 10px !important;
  }
}
.mb-0{
  margin-bottom: 0 !important;
}

.ml {
  &-0 {
    margin-left: 0;
  }
}

.m {
  &-0 {
    margin: 0 !important;
  }
}
