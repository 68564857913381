.invite-team {
  &__table-container {
    .ant-select {
      display: inline-grid !important;
      width: 90% !important;

      .ant-select-selection__placeholder {
        text-align: center;
        width: 100%;
      }

      .ant-select-selection-selected-value {
        padding-top: 0 !important;
        text-align: center;
        width: 100%;
      }
    }
  }
}

