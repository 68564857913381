.send {
  &__restore-container {
    margin-bottom: 30px;

    & span {
      font-size: 16px;
    }
  }

  &__restore-box {
    width: 482px;
    height: 430px;
    padding: 50px 70px;
    box-shadow: 0px 3px 15px #00000029;
    background: #fff;
  }
}

@media screen and (max-width: 768px) {
  .send__restore-box {
    box-shadow: none;
    height: 66%;
    background: none;
    width: 90%;
  }
}
