.sa-ant-collapse {
  .ant-collapse-borderless {
    border: 1px solid #d9d9d9 !important;
    border-bottom: none;
  }

  .ant-collapse-item-active {
    border: 0 !important;
    border-radius: 0;
  }

  .ant-collapse-content {
    background-color: white !important;
    border-top: 1px solid #d9d9d9 !important;

    .ant-collapse-header {
      background-color: #fff;
    }

    .ant-collapse-content-box {
      padding: 0 !important;

      .ant-collapse {
        border: 0;
        border-radius: initial;
      }
    }
  }

  .ant-collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-switch-disabled {
      transform: translateX(0%) !important;
    }
  }
}

.subForm-container {
  padding: 10px 20px;
  border-bottom: 1px solid #d9d9d9 !important;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  div {
    display: inline;
    margin-left: 5px;
  }
}

.subForm-name {
  margin: 0;
  display: inline;
}

.subForm-container:hover {
  background-color: #0b734f31;
}

.subform-collapse {
  border-radius: initial;
}

.skeleton-plans {
  display: block !important;
  width: 140px;
  height: 0.7rem !important;
  margin-right: 15px;
  border-radius: 2px;
}
