.session-header__container {
  position: absolute;
  top: 0;
  max-width: 100% !important;
  margin-top: 42px !important;
  margin-left: 77px !important;
}

.session__logo {
  width: 187px;
}
