.reports {
  &__container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;

    & h1 {
      width: 90%;
      margin: auto;
      margin-top: 50px;
    }
  }

  &__power-bi {
    width: 100%;
    height: 80%;
    bottom: 50px;
    border: none;
  }
}
