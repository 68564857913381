.invite-team {
  
  &__table-container{
    width: 100%;

    .ant-switch{
      transform: translateX(0px);
    }

    .ant-select-selection{
      display: initial;
    }
  }

  &-title {    
    padding-left: 5%;
    padding-bottom: 2%;
  }
  &-selection {
    display: flex;
    padding: 0% 5%;   
  
    select {      
      height: 27px;
      width: 220px;
      font-size: 18px;
    }

    span {
      font-weight: 800;
    }
  }
}

.select{
  max-width: 9rem;
}
