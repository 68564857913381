@media screen and (min-width: 38px) {
  .sm-w-10 {
    width: 10%;
  }
  .sm-w-15 {
    width: 15%;
  }
  .sm-w-20 {
    width: 20%;
  }
  .sm-w-25 {
    width: 25%;
  }
  .sm-w-30 {
    width: 30%;
  }
  .sm-w-35 {
    width: 35%;
  }
  .sm-w-40 {
    width: 40%;
  }
  .sm-w-45 {
    width: 48%;
  }
  .sm-w-50 {
    width: 50%;
  }
  .sm-w-55 {
    width: 55%;
  }
  .sm-w-60 {
    width: 60%;
  }
  .sm-w-65 {
    width: 65%;
  }
  .sm-w-70 {
    width: 70%;
  }
  .sm-w-75 {
    width: 75%;
  }
  .sm-w-80 {
    width: 80%;
  }
  .sm-w-85 {
    width: 85%;
  }
  .sm-w-90 {
    width: 90%;
  }
  .sm-w-95 {
    width: 95%;
  }
  .sm-w-100 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .md-w-10 {
    width: 10%;
  }
  .md-w-15 {
    width: 15%;
  }
  .md-w-20 {
    width: 20%;
  }
  .md-w-25 {
    width: 25%;
  }
  .md-w-30 {
    width: 30%;
  }
  .md-w-35 {
    width: 35%;
  }
  .md-w-40 {
    width: 40%;
  }
  .md-w-45 {
    width: 48%;
  }
  .md-w-50 {
    width: 50%;
  }
  .md-w-55 {
    width: 55%;
  }
  .md-w-60 {
    width: 60%;
  }
  .md-w-65 {
    width: 65%;
  }
  .md-w-70 {
    width: 70%;
  }
  .md-w-75 {
    width: 75%;
  }
  .md-w-80 {
    width: 80%;
  }
  .md-w-85 {
    width: 85%;
  }
  .md-w-90 {
    width: 90%;
  }
  .md-w-95 {
    width: 95%;
  }
  .md-w-100 {
    width: 100%;
  }
}

@media screen and (min-width: 1028px) {
  .lg-w-10 {
    width: 10%;
  }
  .lg-w-15 {
    width: 15%;
  }
  .lg-w-20 {
    width: 20%;
  }
  .lg-w-25 {
    width: 25%;
  }
  .lg-w-30 {
    width: 30%;
  }
  .lg-w-35 {
    width: 35%;
  }
  .lg-w-40 {
    width: 40%;
  }
  .lg-w-45 {
    width: 48%;
  }
  .lg-w-50 {
    width: 50%;
  }
  .lg-w-55 {
    width: 55%;
  }
  .lg-w-60 {
    width: 60%;
  }
  .lg-w-65 {
    width: 65%;
  }
  .lg-w-70 {
    width: 70%;
  }
  .lg-w-75 {
    width: 75%;
  }
  .lg-w-80 {
    width: 80%;
  }
  .lg-w-85 {
    width: 85%;
  }
  .lg-w-90 {
    width: 90%;
  }
  .lg-w-95 {
    width: 95%;
  }
  .lg-w-100 {
    width: 100%;
  }
}
