.accounts-plans {
  &__container {
    width: 100%;
    // max-height: 700px;
    display: flex;
    overflow: auto;
  }

  &__safety-plans {
    overflow: auto;
    max-height: 519px;
    border-bottom: none;
  }

  &__item {
    height: 0;
    max-height: 200px;
    overflow: auto;
    box-shadow: 0px 1px 6px #00000029;
    transition: all 0.3s ease;

    & button.ant-switch-small.ant-switch.ant-switch-disabled {
      margin-top: 0px;
      margin-left: 30px;
      margin-right: 5px;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 10px 15px 10px;
      box-shadow: 0px 1px 3px #00000029;
      cursor: pointer;
      font-weight: bolder;

      & h4,
      & span {
        margin-bottom: 0px;
      }
    }

    &--expanded {
      height: 200px;
    }
  }

  &__table-container {
    justify-self: center;
  }

  &__questions {
    width: 65%;
    padding-left: 2rem;

    .spinner {
      transform: translate(-50%, 700%) !important;
    }
  }
}
