.login {
  &__title {
    font: Bold 30px/35px Roboto, sans-serif;
    margin-bottom: 18px;
  }
  &__form-label {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 0px !important;
  }
  &__email-link {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px !important;
  }
  &__session-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
  }
  &__remember .ant-checkbox-input {
    border-radius: 40px !important;
  }
  &__forgot-password {
    font-size: 18px;
    font-family: Roboto;
    text-decoration: underline;
  }
  &__error-message {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
  }
  &__session-options span.ant-checkbox.ant-checkbox-indeterminate span::after {
    background-color: #0b734f;
  }
}

.opacity-0 {
  opacity: 0;
}

.swal2-icon.swal2-warning {
  border-color: darkseagreen;
  color: #0b734f;
}
