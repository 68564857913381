.sa {
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    max-height: calc(100vh - 60px);
    padding: 20px;
  }

  &__box {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #e9e9e9;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 0 3.2px 7.2px 0 var(--callout-shadow-color, rgba(0, 0, 0, 0.132)),
      0 0.6px 1.8px 0 var(--callout-shadow-secondary-color, rgba(0, 0, 0, 0.108));

    &-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-radius: 3px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      background: #f4f6f4;
      border-bottom: 1px solid #e9e9e9;

      &-title {
        font-weight: 600;
        font-size: 20px;
        margin: 0px 0px !important;
      }
    }

    &-content {
      padding: 10px 20px 10px 20px;
      overflow: auto;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .sa {
    &__box {
      &-content {
        padding: 10px 20px 10px 20px;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

// For Skeleton Loader

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.sa-info-table {
  tbody {
    tr {
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;

      span {
        margin-left: 0.5rem;
        font-size: 14px;
        font-weight: initial;
      }

      div {
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #adadae;
    border-radius: 5px;
    transition: all 0.5s;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #adadae9f;
  }

  ::-webkit-scrollbar-thumb {
    background: #adadae;
    border-radius: 5px;
  }
}

.ant-checkbox-inner {
  border-color: #0b734f !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0b734f;
  border-color: #0b734f !important;
}

.ant-checkbox-checked::after {
  border-color: #0b734f;
}

.ant-checkbox-inner:hover {
  border-color: #0b734f !important;
}

.ant-btn.ant-btn-primary {
  background: #0b734f !important;
  border-color: #1e5d47 !important;

  --antd-wave-shadow-color: rgba(0, 0, 0, 0.65);

  .anticon {
    color: white;
  }
}

.ant-select-selection {
  border: 1px solid #d9d9d9;
  transition: all 0.5s;
}

.ant-select-selection:hover {
  border: 1px solid #a7a4a4;
}

.ant-select-selection:focus {
  box-shadow: 0 0 0 2px rgba(176, 175, 175, 0.2);
  border: 1px solid #a7a4a4;
}

.ant-spin-dot-item {
  background-color: #1e5d47 !important;
}

.sa-input-time {
  width: 100%;

  .ant-calendar-picker-input {
    background: #f2f6f5 !important;
    border: none !important;
    font-size: 16px !important;
    height: 45px;
  }
}

.ant-dropdown-menu-item-selected {
  color: #0b734f;
  background-color: #0b734f31;
}

.ant-table-filter-dropdown-btns {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  .clear {
    color: #0b734f;
    transition: all 0.5s;

    &:hover {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .confirm {
    color: #0b734f;
    transition: all 0.5s;

    &:hover {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: calc(100vh - 400px);
}

.ant-table-thead > tr > th .ant-table-filter-selected.anticon {
  color: #0b734f !important;
}

.sa-select {
  .ant-select-selection {
    background: #f2f6f5 !important;
    border: none !important;
    height: 45px !important;
    font-size: 14px !important;
    width: 100%;

    .ant-select-selection__rendered {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.sa-input {
  background: #f2f6f5 !important;
  border: none !important;
  height: 45px !important;
  font-size: 14px !important;
}

.sa-textarea {
  background: #f2f6f5 !important;
  border: none !important;
  font-size: 14px !important;
}

.input--medium-height,
.input--medium-height input,
.input--medium-height .ant-select-selection--single {
  height: 45px !important;
  background: #f2f6f5 !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  border: none !important;

  .ant-select-selection__rendered {
    line-height: inherit !important;
  }

  .ant-select-selection__placeholder {
    top: 94% !important;
  }

  .ant-select-selection-selected-value {
    position: absolute;
    top: 40%;
  }

  .ant-select-selection {
    width: 100%;
  }
}

.ant-calendar-today-btn {
  color: #0b734f;
}

.ant-calendar-time-picker-btn {
  text-transform: capitalize;
  color: #0b734f;

  &:hover {
    color: #0b734f;
  }
}

.ant-calendar-ok-btn {
  background-color: #0b734f !important;
  border-color: #0b734f !important;
}

.ant-calendar-selected-day .ant-calendar-date {
  background: #0b734f31;
}

.table-sa {
  .ant-form-explain {
    display: none;
  }

  &__clickable {
    white-space: nowrap;
    color: #22c55e;
    font-weight: 700;
    transition: all 0.5s;

    &:hover {
      text-decoration: underline;
      color: #22c55e;
    }
  }

  &__actionIcon {
    font-size: 16px;
    transition: all 0.2s;

    &:hover {
      scale: 120%;
      color: #22c55e;
    }
  }

  &__deleteIcon {
    font-size: 16px;
    transition: all 0.2s;

    &:hover {
      scale: 120%;
      color: red;
    }
  }
}

.sa-collapse {
  border: 1px solid #eee;

  &:not(:last-child) {
    border-bottom: none;
  }

  &__header {
    list-style-type: none;
    padding: 1rem;
    background-color: #f9f9f9;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    &::before {
      content: url(../../assets/icons/plus-icon.svg);
      display: flex;
      margin-top: 4px;
      font-size: 1rem;
      font-weight: bold;
    }
  }

  &[open] > &__header::before {
    content: url(../../assets/icons/minus-icon.svg);
  }

  &[open] > &__header {
    border-bottom: none;
  }

  &[open] {
    border-bottom: none;
  }
}

.sa-drag-border {
  border: 3px dotted #d1d7db !important;
}

.edit-icon {
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    scale: 120%;
    color: #22c55e;
  }
}

.hover-icon {
  transition: all 0.2s;

  &:hover {
    scale: 120%;
  }
}

.danger-icon {
  font-size: 16px;
  transition: all 0.2s;

  &:hover {
    scale: 120%;
    color: red;
  }
}



.form-modal {
  .ant-modal-body {
      .ant-form {
          overflow: auto;
          max-height: 500px;
          padding: 0px 10px !important;

          .container {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              gap: 1rem;
          }
      }
  }
}

.sa-table-clickable {
  white-space: nowrap;
  color: #22c55e;
  font-weight: bold;
  transition: 0.5s all;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: #22c55e;
  }
}