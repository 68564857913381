.plans__list {
  font-size: 13px;

  @media only screen and (min-width: 1366px) {
    font-size: 17px;
  }
}

.plans__ {
  &list > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 45px 15px 0px;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  &item.active {
    border-right: none !important;
  }

  &item.active.completed {
    background: none;
  }

  &item p {
    padding-left: 40px;
    margin-bottom: 0px !important;
  }
}
