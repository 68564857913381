.view-package {

  .ant-btn:hover,
  .ant-btn:focus {
    border-color: #0B734F !important;
  }

  &__table-container {
    .sa-table {
      margin: 0;
      tbody td {
        padding: 10px 5px !important;
      }
    }
  }
}