.accounts {
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 96%;
    margin: 40px auto;
  }

  &__heading {
    align-items: baseline;
    width: 100%;
  }

  &__create-btn {
    margin: 0px 50px;
    span{
      padding: 0px !important;
      margin: 0px 30px;
    }
  }

  &__content {
    width: 100%;

    &-package-heading{
      gap: 20px;
    }
  }

  &__table-container{
    width: 40%;
  }
}
