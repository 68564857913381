.programs {
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: border-box;
    height: 100vh;
  }

  &__buttons {
    left: 0px;
    bottom: 0;
    width: 100%;
    padding: 0px 50px;
    transform: translateY(-30px);

    @media (max-height: 700px) and (min-width: 500px) {
      position: initial;
      transform: translateY(0px);
    }

    @media (min-width: 992px) {
      padding: 0px 100px;
      padding-left: 15%;
      padding-bottom: 2%;
    }

    & .btn__large {
      width: 40%;

      @media (min-width: 1200px) {
        width: 310px;
      }
    }
  }
}

.ant-table-column-title {
  font-size: 16px;
  font-weight: normal !important;
  color: #707070ef !important;
  text-transform: capitalize;
}

.steps__tabs {
  width: 90%;
  margin: 50px auto 0px;
}

.d-none {
  display: none;
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  border-left-color: #0b734f !important;
  border-right-color: #0b734f !important;
}
