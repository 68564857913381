$fontXS: 10px;
$fontBase: 12px;
$fontMedium: 14px;
$fontlarge: 16px;
$fontXL: 18px;


.sa-tree {
    .ant-modal-body {
        .ant-tree {
            overflow: auto;
            font-size: $fontXS;
            max-height: 400px;
        }
    }
}

@media (min-width: 320px) {
    .sa-tree {
        .ant-modal-body {
            padding: 0 !important;
        }
    }
}

@media (min-width: 375px) {
    .sa-tree {
        .ant-modal-body {
            padding: 0px 5px !important;

            .ant-tree {
                font-size: $fontBase;
            }
        }
    }
}

@media (min-width: 414px) {
    .sa-tree {
        .ant-modal-body {
            padding: 10px !important;
        }
    }
}

@media (min-width: 768px) {
    .sa-tree {
        .ant-modal-body {
            .ant-tree {
                font-size: $fontMedium;
            }
        }
    }
}

@media (min-width: 1024px) {
    .sa-tree {
        .ant-modal-body {
            .ant-tree {
                max-height: 450px;
            }
        }
    }
}