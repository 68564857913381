.dialog {
  &-display {
    color: white;
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
    opacity: 1; /* Initially hidden */
    display: block;
    transition: opacity 0.3s ease-in-out;
  }

  &-hide {
    opacity: 0; /* Initially hidden */
    display: none;
  }
  &__container {
    border-radius: 4px;
    background: white;
    position: fixed;
    width: 80%;
    overflow: hidden;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
  }

  &-header {
    position: relative;
    background-color: #f3f4f6;
    padding: 1rem;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);

    &__title {
      color: hsla(0, 0%, 0%, 0.7);
      font-weight: 800;
      font-size: 16px;
    }

    &__close-icon {
      color: gray !important;
      float: right;
      position: absolute;
      right: 20px;
      top: 10px;
      background-color: white;
      top: 50%; /* Position Y halfway in */
      transform: translateY(-50%); /* Move it halfway back(x,y) */
      padding: 0.3rem;
      border-radius: 8px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
      transition: all 0.2s;

      &:hover {
        color: black !important;
        font-size: 16px;
      }
    }
  }
  &-body {
    padding: 1rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
    overflow-y: auto;
  }
  &-close {
    padding: 10px 30px;
    color: white;
    float: right;
    font-size: 35px;
    font-weight: lighter;

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__form-footer {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}
