.dzu-input {
    font-family: sans-serif;
    margin: 15px;
    
}

.custome-dropzone {
    font-weight: bolder;
    &_success {
        color: green;
    }
    &_error {
        color: red;
    }
}