.main-header {
  &__container {
    background: #fff;
    padding: 10px;
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    min-height: 60px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15) !important;

    img {
      height: 40px;
      margin: 0rem 1rem;
    }

    &-header {
      .anticon {
        color: white;
      }
    }

    &-content {
      margin: 0px 20px;
      display: flex;
      align-items: center;

      img {
        height: 35px;
        margin: 0rem 1rem 0rem 0rem;
      }

      --antd-wave-shadow-color: none !important;

      ::selection {
        color: #fff;
        background: #0b734f;
      }

      .ant-btn:focus {
        color: #0b734f !important;
      }

      .anticon-menu-fold {
        display: none;
      }
    }

    &-logout {
      display: inline-block;

      .anticon-down {
        margin: 0px 14px;
      }
    }

    &-dropdown_title {
      color: #0b734f !important;
      font-weight: 500;
      font-size: 16px;
    }

    &-dropdown_item {
      .anticon-logout {
        color: #ff4d4f;
        font-size: 18px;
        transform: translate(0px, 2px);
      }
    }

    .ant-btn {
      border-color: transparent !important;
      box-shadow: none;
      -webkit-box-shadow: transparent;
    }

    .ant-btn:hover {
      color: rgb(0, 100, 53);
    }

  
  }

  @media (max-width: 426px) {
    &__container {
      img {
        height: 30px;
        margin: 0rem 0.5rem;
      }

      &-content {
        margin: 0px;

        button {
          padding: 0px;

          .btn-text {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__tabs {
    width: 100%;
    height: 95%;

    .ant-tabs-content {
      height: 100%;
    }

    .ant-tabs-nav {
      display: flex;
      justify-content: center;
    }

    .ant-tabs-ink-bar {
      left: 38px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-header {
    &__container {
      &-content {
        margin: 0px;

        .anticon-menu-fold {
          font-size: 18px;
          display: inline-block;
          color: #0b734f;
        }
      }

      &-logout {
        display: none;
      }
    }
  }
}

.main-header__drawer {
  text-align: left;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  margin: 0rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.ant-drawer-wrapper-body {
  overflow: hidden !important;
}

.main-header__drawer:hover {
  background-color: #0b734f31;
}

.ant-drawer-body {
  padding: 0rem 0rem !important;
  display: flex;
  height: 90%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .content-container {
    height: calc(100% - 50px);
    width: 100%;
    overflow: auto;
    display: flex !important;
    justify-content: center;
    margin: 0rem 0rem !important;

    &__list {
      width: 100%;
    }
  }

  .ant-btn {
    position: absolute;
    width: 90%;
    bottom: 10px;
  }
}

.btn-text {
  font-weight: 500;
  margin: 0;

  span {
    margin: 0px 0rem 0 0.5rem;
  }
}
