@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.certificates-modal {
  &__container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
    max-height: 420px;
    overflow: auto;
  }

  &__card-heading {
    display: flex;
    align-items: center;
    gap: 10px;

    .anticon-mail:hover {
      color: darkgreen;
      transition: 0.5s all;
    }
  }

  &__subcontainer {
    background-color: #f4f6f4 !important;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  &__img-container {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    max-height: 250px;
    height: 250px;
    object-fit: contain;

    img {
      height: 100%;
      width: 100%;
      max-width: 100%;
      border: 1px solid #e8e8e8;
      border-top: none;
    }

    .ant-carousel {
      height: 100%;

      .slick-slider {
        height: 100%;
      }
    }
  }

  &__info-container {
    padding: 10px 5px;

    tr {
      font-size: 12px;
      font-weight: 500;

      h3 {
        margin-bottom: 0px;
        font-size: 16px;
        letter-spacing: 0.5px;
      }

      .info {
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }

  &__empty {
    grid-column: 1 / span 2;

    .ant-empty {
      margin: 20px 8px;

      .ant-empty-image {
        svg {
          height: 100px !important;
          width: 100px !important;
        }
      }

      .ant-empty-description {
        font-weight: bold;
      }
    }
  }

  &__loading {
    grid-column: 1 / span 2;
    border: none;
    margin: 40px;
  }

  &__img-backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: black;
    opacity: 0;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;

    .title {
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
      color: white;
      margin-bottom: 5px;
    }
  }

  &__img-backdrop:hover {
    opacity: 0.3;
    cursor: pointer;
  }
}

.certificates-modal {
  max-width: 1000px;
  width: initial !important;
  top: 50px !important;

  .ant-modal-content {
    min-width: 600px;
  }

  .ant-modal-body {
    padding: 0 !important;

    .tabs {
      .ant-tabs-nav-scroll {
        .ant-tabs-tab {
          margin: 0;
        }
      }

      .ant-tabs-content {
        .ant-tabs-tabpane {
          padding: 10px 15px;
        }
      }
    }
  }
}

.image-modal {
  max-width: 850px;
  width: auto !important;
  top: 50px !important;

  .ant-modal-content {
    min-width: 700px;
  }

  &__InfoContainer {
    display: flex;
    flex-direction: column;

    .dates {
      display: flex;
      gap: 1rem;

      h3 {
        font-weight: normal;
      }
    }
  }

  &__ImgContainer {
    width: 100%;
    height: 100%;
    height: 500px;
    max-height: max-content;

    .ant-carousel {
      height: 100%;

      .slick-slider {
        height: 100%;

        .slick-list {
          height: 100%;
        }

        .slick-track {
          height: 100%;

          .slick-slide {
            div {
              max-height: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #e8e8e8;
              background-color: #f4f6f4;
              border-radius: 5px;

              img {
                max-height: 100%;
                border: 1px solid #e8e8e8;
                border-collapse: collapse;
                width: initial !important;
              }
            }
          }
        }
      }
    }

    img {
      max-width: 100%;
    }

    .slick-arrow {
      display: none !important;
      background: rgba(0, 0, 0, 0.4) !important;
      border-radius: 5px;
      padding: 0rem;
      width: 30px !important;
      height: 30px !important;
      justify-content: center;
      align-items: center;

      svg {
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        height: 20px;
        width: 20px;
        fill: white;
      }

      svg:hover {
        scale: 120%;
      }
    }

    .slick-prev {
      left: 5px !important;
      z-index: 1;
    }

    .slick-next {
      right: 5px !important;
      z-index: 1;
    }
  }
}

.image-modal__ImgContainer:hover .slick-arrow {
  display: flex !important;
}

.ant-carousel {
  .slick-dots {
    li {
      button {
        background: black !important;
        border-radius: 20px !important;
      }
    }
  }
}

.role-change-modal {
  .ant-modal-body {
    padding: 0 !important;

    .ant-modal-confirm-title {
      padding: 10px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: #f4f6f4 !important;
      border-bottom: 1px solid #e8e8e8;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 600;
    }

    .ant-modal-confirm-content {
      padding: 0px 10px;
    }
  }
}
