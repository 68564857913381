.project-forms {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  th {
    text-align: center !important;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;


    .ant-form-item-control {
      line-height: initial !important;

      .ant-select-selection:hover {
        border-color: gray;
      }

      .ant-select-selection:focus {
        border-color: gray;
        box-shadow: 0 0 0 1px rgba(11, 115, 79, 0.1);
      }

      .ant-calendar-picker {
        .ant-input:hover {
          border-color: gray;
          box-shadow: 0 0 0 1px rgba(11, 115, 79, 0.1);
        }
      }

      .misc{
        display: flex;
        justify-content: center;
        line-height: 30px;
        transform: translateX(-4px);
      }
    }

    

  }

  &__filters-form {
    .ant-form-item-label {
      font-weight: bold;
    }
  }
}