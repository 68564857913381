.envindicator-container {
  color: #707070ef;
  opacity: 0.5;
  background: transparent;
  position: fixed;
  bottom: 0%;
  right: 0%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .env-item {
    padding-right: 15px;
  }
}
