.aside {
  width: 31%;
}

.image--aside {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 70%;
  width: 100%;
  margin-bottom: 14%;
}

.header {
  display: none;
}

.children {
  display: flex;
  width: 65%;
}

@media (max-width: 500px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 20%;
    min-height: 151.96px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .main-div {
    flex-direction: column;
    padding: 0px 9px;
    margin-top: 5.64px;
  }
  .aside {
    display: none;
  }
  .header-logo {
    width: 187px;
    height: 54.66px;
    margin: 0;
    margin-top: 14.76px;
  }
  .children {
    width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .aside {
    height: auto !important;
  }
}

@media screen and (min-height: 908px) {
  .aside {
    height: 100vh !important;
  }
}

@media (max-width: 777px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 20%;
    min-height: 220px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }
  .main-div {
    flex-direction: column;
    padding: 0px 9px;
    margin-top: 5.64px;
  }
  .aside {
    display: none;
  }
  .header-logo {
    margin-top: 20px;
    margin-left: 30px;
    object-fit: contain;
  }
  .children {
    width: 100%;
  }
}
