.ca-table-clickable {
  white-space: nowrap;
  color: #22c55e;
  font-weight: bold;
  transition: 0.5s all;

  &:hover {
    text-decoration: underline;
    color: #22c55e;
  }
}

.ca-table-actions {
  display: flex !important;
  align-items: center;

  .anticon-eye {
    font-size: 16px !important;
    // background-color: #ffffff50;
  }
}

.ca-table {
  overflow: auto;

  .ant-table-thead {
    .ant-table-header-column {
      .ant-table-column-title {
        font-weight: 500 !important;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        vertical-align: top !important;
      }
    }
  }
}

// View History || View Details Styles

.main-container {
  padding: 10px;
  overflow: auto;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  .form {
    grid-column: span 2 / span 2;

    .content-container {
      display: grid;
      padding: 0px 10px;
      gap: 2rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      transition: 0.5s all;

      &:hover {
        background-color: #fafafa;
      }

      .ant-form-item {
        margin-bottom: 10px !important;
      }
    }
  }

  .content-container:nth-child(even) {
    border-top: 1px solid #f4f6f4;
    border-bottom: 1px solid #f4f6f4;
  }
}

.history-container {
  padding: 10px;

  &__heading {
    font-size: 18px;
    text-decoration: underline;
    font-weight: 600;
    color: #22c55e;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 60vh;
    overflow: auto;
    padding: 0px 10px;

    .history-card {
      border-radius: 8px;

      .ant-card-body {
        display: flex;
        flex-direction: column;
        padding: 10px !important;
        transition: 0.5 all;

        div {
          padding: 10px;

          &:hover {
            background-color: #fafafa;
          }
        }

        div:nth-child(even) {
          border-top: 1px solid #f4f6f4;
          border-bottom: 1px solid #f4f6f4;
        }
      }
    }
  }
}

.card {
  border-radius: 5px !important;
  height: max-content;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 10px !important;
    transition: 0.5 all;

    div {
      padding: 10px;

      &:hover {
        background-color: #fafafa;
      }
    }

    div:nth-child(even) {
      border-top: 1px solid #f4f6f4;
      border-bottom: 1px solid #f4f6f4;
    }
  }
}


.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color: #22c55e !important;

}