.team-locked {
  &__container {
    width: 100%;
    margin: auto;

    & .professional__header-container {
      position: unset;
    }
  }
  &__button {
    float: right;
    margin-bottom: 20px !important;
    border-color: green !important;
  }

  &__view {
    width: 100%;
    max-height: 700px;
    overflow-y: auto;
    margin-top: 20px;
  }
}

.team-select,
.team__select {
  &--centered {
    width: 8rem;
    text-align: center;
    background: transparent ;
  }
}
