.helpform {
  &-display {
    color: white;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
  }
  &__container {
    border-radius: 4px;
    border: 2px solid white;
    position: fixed;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
  }
  &-body {
    background: white;
    padding: 2px 16px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &-close {
    padding: 10px 30px;
    color: white;
    float: right;
    font-size: 35px;
    font-weight: lighter;

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
      cursor: pointer;
    }
  }
  &__subject-container,
  &__description-container,
  &__submit-container {
    margin: 5px, 30px;
  }
  &close{
    cursor: pointer;
    font-size: 30px;
    color: gray;
    padding-left: 580px;
    padding-bottom: 0px;
  }
  &close:hover{
    color: rgb(98, 97, 97);
  }
}
