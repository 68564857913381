.dzu-input {
    font-family: sans-serif;
    margin: 15px;

}

.custome-dropzone {
    font-weight: bolder;

    &_success {
        color: green;
    }

    &_error {
        color: red;
    }
}

.inputs-styles {
    padding: 0px 5px 8px 5px !important;
    font-weight: 500;
    margin-bottom: 8px !important;
}