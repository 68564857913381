.not-found .background__logo {
  position: absolute;
  opacity: 0.4;
}

.not-found .session__body__container {
  top: 50%;
  width: 60%;
}

.not-found .btn__container {
  width: 200px;
}

@media screen and (min-width: 768px) {
  .not-found .session__body__container {
    width: 100%;
  }

  .not-found .btn__container {
    width: 310px;
  }
}
