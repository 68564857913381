.account-team {
  &__table-container {
    width: 100%;
    max-height: 800px;
    overflow: auto;
  }
}

.ant-tree-node-content-wrapper:has(.inactive-user) {
  background-color: #ff4d5036 !important;
}

.table-accounts {
  &__sorter {
    margin-left: 5px;
  }

  &__team {
    width: 100%;
    border-collapse: inherit;
    border-spacing: 0px 10px;

    & tr th {
      font-weight: normal;
      text-align: center;
      padding: 10px 0px;
      cursor: pointer;
    }

    & tbody tr {
      height: 60px;
      transition: all 0.3s ease;
    }

    & td {
      padding: 10px;
      text-align: center;
    }

    &__loading-title {
      margin-top: 20px;
    }
  }
}


.ant-tree-node-content-wrapper:has(.inactive) {
  background-color: #ff4d5036 !important;

  &:hover{
  background-color: #ff4d5036 !important;

  }
}

.ant-tree-node-content-wrapper:has(.active) {
  background-color: #0b734f31 !important;
}

.remove-associate {
  .ant-modal-body {
    padding: 0 !important;

    .ant-modal-confirm-content {
      .warn-text {
        width: 100%;
        display: block;
        text-align: center;
        font-size: 16px;
      }

      .note-text {
        display: block;
        max-width: 550px;
        word-wrap: break-word;
        padding: 0px 10px;
      }

      .ant-table-wrapper {
        padding: 20px 20px;
        max-width: 550px;
        padding-bottom: 0px;

        .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
          background-color: #fafafa !important;
        }
      }
    }

    .ant-modal-confirm-btns {
      float: initial;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      gap: 10px;
      padding: 10px;
    }
  }
}
