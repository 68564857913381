.logo-container {
  position: relative;
  margin: 0px 40px;
}

.container {
  .ant-select-selection {
    display: inline-block !important;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  transition: background 0.5s ease;
}

.logo-container:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3);
}

.company-logo {
  position: relative;
  height: 200px;
}

.delete-icon {
  position: absolute;
  top: 8px;
  right: 10px;
  opacity: 0;
}

.delete-icon i {
  color: white;
  z-index: 1;
  cursor: pointer;
}

.logo-container:hover .delete-icon {
  opacity: 1;
}

.table_fix {
  margin-top: 10px;

  th {
    text-align: center;
  }
}

