.registration {
  &__container {
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 65px auto 60px auto;
    transform: translateY(40px);
  }

  &__heading {
    font-size: 26px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 30px;
  }

  &__title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
  }

  &__form-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px !important;
  }
}

.main-div .loading-spinner {
  position: inherit;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 300px) {
  .registration {
    &__container {
      transform: translateY(-40px);
    }

    &__form-label.lg-w-30.md-w-30.sm-w-25.birthday-select > div.ant-col.ant-form-item-label {
      padding: 0 0 5px;
    }

    &__form-label.lg-w-30.md-w-30.sm-w-30.birthday-select.month-select
      > div.ant-col.ant-form-item-label {
      padding: 0 0 5px;
    }
  }

  .swal2 {
    &-popup.swal2-modal.swal2-show {
      font-size: 14px;
    }

    &-actions {
      margin: 2.5em auto 0;
    }
  }
  .bday-container {
    width: 100%;
  }
}

@media screen and (min-width: 770px) {
  .month-select {
    width: auto;
  }
}

@media screen and (min-width: 962px) {
  .month-select {
    width: 30%;
  }
}

@media screen and (min-width: 640px) {
  .registration__container {
    transform: translateY(0px);
    margin: auto;
  }
  .bday-container {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .registration {
    &__form-label.lg-w-30.md-w-30.sm-w-30.birthday-select.month-select
      > div.ant-col.ant-form-item-label {
      padding: 0 0 8px;
    }

    &__form-label.lg-w-30.md-w-30.sm-w-25.year-select > div.ant-col.ant-form-item-label {
      padding: 0 0 8px;
    }
  }
  .addres-container {
    width: 55%;
  }
  .bday-container {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .registration {
    &__container {
      width: 90%;
      text-align: 'center';
    }

    &__form-label {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 5px !important;
    }
  }
  .addres-container {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .registration {
    &__form-label.lg-w-30.md-w-30.sm-w-30.birthday-select.month-select
      > div.ant-col.ant-form-item-label {
      padding: 0 0 8px;
      background: transparent;
    }

    &__form-label.lg-w-30.md-w-30.sm-w-25.year-select > div.ant-col.ant-form-item-label {
      padding: 0 0 8px;
      background: transparent;
    }
  }

  .swal2-popup.swal2-modal.swal2-show {
    font-size: 14px;
  }
  .button-container {
    justify-content: flex-end;
  }
}

.birthday-label .ant-col.ant-form-item-label {
  overflow: inherit;
}
