.safety-programs {
  margin: 1rem 2rem;
  display: grid;
  grid-template-columns: 0.6fr 1.4fr;
  gap: 2rem;
  width: 100%;

  &__collapse-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    border-radius: 8px;
    padding-bottom: 10px;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);

    &_header {
      background-color: #f3f4f6;
      padding: 0.5rem 1rem;
      width: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }

    &_content {
      margin-right: 10px;
      padding-left: 1rem;
      padding-right: 10px;
      min-height: calc(100vh - 160px);
      max-height: calc(100vh - 160px);
      overflow: auto;
      width: 100%;
    }

    .form {
      padding: 1rem;
      padding-left: 4rem;
      transition: all 0.5s;
      display: flex;
      justify-content: space-between;
      &:nth-child(n) {
        border-top: 1px solid #eee;
      }

      &:hover {
        background-color: #0b734f31;
      }
    }
  }

  &__question-container {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);

    &_header {
      background-color: #f3f4f6;
      padding: 0.5rem 1rem;
      width: 100%;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
    }

    &_body {
      height: 100%;
      width: 100%;
      padding: 1rem;
      padding-right: 0.5rem;

      &-scollable {
        height: 100%;
        max-height: calc(100vh - 170px);
        overflow-y: auto;
        overflow-x: hidden;

        .questions-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-right: 10px;

          .sa-collapse {
            border-bottom: 1px solid #eee;
          }
        }
      }
    }
  }
}
