.loading-spinner {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgb(255, 255, 255);
  z-index: 40;

  &--table {
    position: relative;
    width: 100%;
    height: auto;
  }

  &--plans {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    transform: translate(-50%, -50%);
  }
}
