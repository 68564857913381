.session {
  &__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__header__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__form-box {
    width: 470.45px;
    height: 414px;
    padding: 34px 39.98px 43px 40px;
    box-shadow: 0px 3px 15px #00000029;
    background: #fff;
    box-sizing: border-box;
    border-radius: 4px;
  }

  &__login-form-box {
    width: 482px;
    height: 430px;
    padding: 50px 70px;
    box-shadow: 0px 3px 15px #00000029;
    background: #fff;
  }

  &__contratulations-form-box {
    width: 482px;
    height: 430px;
    padding: 100px 70px;
    box-shadow: 0px 3px 15px #00000029;
    background: #fff;
  }

  &__p {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
    letter-spacing: 0;
  }
}

@media (max-width: 777px) {
  .session__login-form-box {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .session {
    &__form-box {
      box-shadow: none;
      height: 66%;
      padding: 34px 33.98px 7px 35px;
    }

    &__header__container {
      & p {
        font-size: 14px;
      }
    }
    &-header__container {
      display: flex;
      justify-content: flex-end;
      padding-right: 10%;
      margin-left: 35px !important;

      & img {
        height: 100%;
        width: 160px;
        top: 25%;
      }
    }
    &__container {
      transform: translateX(-50%);
      top: 39%;
    }
    &__form-box {
      background: none;
      top: 41%;
    }
    &__body__container {
      top: 23%;
    }
  }

  .login {
    &__session-options {
      & a {
        font-size: 14px;
      }

      & span:nth-child(2) {
        font-size: 14;
      }
    }
  }

  .ant-form-vertical .ant-form-item-control {
    line-height: 4;
  }

  form > div.ant-row.ant-form-item.LoginForm-signinButtonStyles-0-1-8.login__email-link {
    transform: translateY(50px);
  }
}
