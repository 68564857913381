.questions-form {
  &__options-table {
    margin-top: 2rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;

    .ant-table-wrapper {
      width: 100%;

      th {
        padding: 8px 16px !important;

        .ant-table-column-title {
          font-size: 14px;
          font-weight: bolder !important;
        }
      }

      .ant-table-placeholder {
        padding: 0px !important;

        .ant-empty-normal {
          margin: 12px 0px !important;

          .ant-empty-image {
            margin: 0px !important;
            svg {
              width: 50px;
            }
          }
        }
      }
    }
  }
}

.regulations-image {
  position: relative;
  overflow: hidden;
}

.regulations-upload-input {
  opacity: 0;
  position: absolute;
  z-index: 5;
  height: 100%;
}

.regulation-img-container {
  position: relative;
  border: 1px solid #d9d9d9;

  .hover-drop {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    transition: 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  img {
    height: 60px;
    width: 100%;
  }
}

.regulation-img-upload {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  border: 1px dashed #d9d9d9;
  cursor: pointer;
  padding: 10px 25px;

  .hover-drop {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    transition: 0.5s;

    &:hover {
      opacity: 1;
    }
  }
}
