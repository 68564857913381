.create-ca-modal {
    .ant-modal-body {

        .ant-form {
            overflow: auto;
            max-height: 500px;
            padding: 0px 10px !important;

            .container {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                gap: 1rem;
            }
        }
    }
}

.ca-question-option {
    white-space: normal !important;
}